if (!Promise.allSettled) {
    Promise.allSettled = function (promises) {
        let mappedPromises = promises.filter(o => !!o).map(p => {
            return p.then(value => {
                return {
                    status: 'fulfilled',
                    value
                };
            }).catch(reason => {
                return {
                    status: 'rejected',
                    reason
                };
            });
        });
        return Promise.all(mappedPromises);
    };
}
